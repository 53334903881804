export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export enum FontName {
    appetite = 'Appetite',
    gotham = 'Gotham',
}

export interface FormOption {
    label: string;
    value: string;
}

export enum HorizontalAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

export interface PageCoords {
    x: number;
    y: number;
}

export enum StatusCode {
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    gone = 410,
    server = 500,
}

export enum HydrationStationType {
    y2 = 'y2',
    y5 = 'y5',
    y14 = 'y14',
}

interface AsyncReduxProperties {
    isLoading: boolean;
    isSuccessful: boolean;
    error: string;
    statusCode?: StatusCode;
}

export type AsyncReduxState<T = unknown> = Readonly<{
    [P in keyof T]: T[P];
} & AsyncReduxProperties>;
