import { ChangeEvent, FC, ReactElement } from 'react';

import { ErrorLabel, InputLabel, Textarea as TextareaComponent } from '../../../components';
import { TextareaProps as TextareaComponentProps } from '../../../components/@inputs/Textarea/Textarea';

import './Textarea.scss';

interface TextareaProps extends Omit<TextareaComponentProps, 'onChange'> {
    label: string;
    additionalLabel?: string;
    hideLabel?: boolean;
    error?: string;
    onChange: (value: string) => void;
    fieldClassName?: string;
}

const Textarea: FC<TextareaProps> = ({
    label,
    additionalLabel,
    hideLabel = false,
    error = '',
    onChange,
    className = '',
    fieldClassName = '',
    ...textareaProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange(event.currentTarget.value);
    };

    return (
        <label
            aria-label={hideLabel ? label : undefined}
            className={`textarea ${className}`}
        >
            {!hideLabel && (
                <InputLabel
                    label={label}
                    additionalLabel={additionalLabel}
                    className="textarea__label"
                />
            )}

            <TextareaComponent
                {...textareaProps}
                error={error}
                onChange={handleChange}
                className={fieldClassName}
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="textarea__error-label"
                />
            )}
        </label>
    );
};

export default Textarea;
