import {
    FC,
    ReactElement,
    SyntheticEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { Button, LinkButton } from '../../../components';
import { IconButton, Switch } from '../../../compositions';
import { Continent } from '../../../constants/countries';
import { AppRoute, appRoutes } from '../../../constants/routing';
import { useCountry } from '../../../context/CountryContext';
import { ScrollContext } from '../../../context/ScrollContext';
import { isSafari } from '../../../helpers';
import { scrollIntoView } from '../../../helpers/scroll';
import usePrefersReducedMotion from '../../../hooks/usePrefersReducedMotion';
import useTimeout from '../../../hooks/useTimeout';
import useTrans from '../../../hooks/useTrans';
import { FormOption, HydrationStationType } from '../../../types';

import './HydrationStationSection.scss';

enum Chapter {
    activate = 'activate',
    choose = 'choose',
    fill = 'fill',
}

interface HydrationStationSectionProps {
    isInView: boolean;
    className?: string;
}

const HydrationStationSection: FC<HydrationStationSectionProps> = ({
    isInView,
    className = '',
}): ReactElement => {
    const { isScrolling: isScrollingToSection } = useContext(ScrollContext);
    const prefersReducedMotion = usePrefersReducedMotion();
    const { continent } = useCountry();
    const trans = useTrans();

    const chapters: Record<Chapter, number> = {
        [Chapter.activate]: 0,
        [Chapter.choose]: 6,
        [Chapter.fill]: 12,
    };

    const videoWrapperRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const defaultHydrationStationType = continent === Continent.northAmerica ? HydrationStationType.y5 : HydrationStationType.y2;
    const [hydrationStationType, setHydrationStationType] = useState<string>(defaultHydrationStationType);

    const hydrationStationTypeOptions: [FormOption, FormOption] = [
        {
            label: trans('containers.hydrationStationSection.switch.y5'),
            value: HydrationStationType.y5,
        },
        {
            label: trans('containers.hydrationStationSection.switch.y14'),
            value: HydrationStationType.y14,
        },
    ];

    const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);
    const [videoHasEnded, setVideoHasEnded] = useState<boolean>(false);

    const [scrolledPast, setScrollPast] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);

    const positionScroll = (): void => {
        if (videoRef.current) {
            scrollIntoView<HTMLVideoElement>(videoRef);
        }
    };

    const playVideo = (): void => {
        if (videoRef.current) {
            videoRef.current.play();
            positionScroll();
            setVideoIsPlaying(true);
            setVideoHasEnded(false);
        }
    };

    const pauseVideo = (): void => {
        if (videoRef.current) {
            videoRef.current.pause();
            setVideoIsPlaying(false);
        }
    };

    const startChapter = (chapter: Chapter): void => {
        if (videoRef.current) {
            videoRef.current.currentTime = chapters[chapter];

            playVideo();
        }
    };

    const checkScrollPosition = (): void => {
        if (videoRef.current) {
            const { y } = videoRef.current.getBoundingClientRect();
            const distanceFromSnappedPosition = Math.abs(y);
            const scrolledPastThreshold = 25;

            setScrollPast(distanceFromSnappedPosition > scrolledPastThreshold);
        }
    };

    const handleTimeUpdate = (event: SyntheticEvent<HTMLVideoElement, Event>): void => {
        setCurrentTime(event.currentTarget.currentTime);
    };

    const handleVideoEnded = (): void => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;

            setVideoHasEnded(true);
            setVideoIsPlaying(false);
        }
    };

    const scrubToActivateChapter = (): void => startChapter(Chapter.activate);
    const scrubToChooseChapter = (): void => startChapter(Chapter.choose);
    const scrubToFillChapter = (): void => startChapter(Chapter.fill);

    useEffect((): void => {
        if (isInView && !prefersReducedMotion && !isScrollingToSection) {
            playVideo();
        } else {
            pauseVideo();
        }
    }, [isInView, isScrollingToSection]);

    useEffect((): void => {
        if (videoRef.current) {
            videoRef.current.currentTime = currentTime;
        }

        if (!videoIsPlaying || prefersReducedMotion) {
            pauseVideo();
        }
    }, [hydrationStationType]);

    useEffect((): void => {
        const newHydrationStationType = continent === Continent.northAmerica
            ? HydrationStationType.y5
            : HydrationStationType.y2;

        setHydrationStationType(newHydrationStationType);
    }, [continent]);

    useEffect((): () => void => {
        window.addEventListener('scroll', checkScrollPosition);

        return (): void => {
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    useTimeout((): void => {
        if (videoIsPlaying && scrolledPast) {
            positionScroll();
        }
    }, 1000, [videoIsPlaying, scrolledPast]);

    const videoData = isSafari()
        ? { src: `/videos/hydration-station-${hydrationStationType}.mp4`, type: 'video/mp4' }
        : { src: `/videos/hydration-station-${hydrationStationType}.webm`, type: 'video/webm' };

    const mobileVideoSrc = `/videos/hydration-station-${hydrationStationType}-mobile.mp4`;

    const videoWrapperClassNames = classNames('hydration-station-section__video-wrapper', {
        'hydration-station-section__video-wrapper--is-mobile': isMobile,
    });

    return (
        <div ref={videoWrapperRef} className={`hydration-station-section ${className}`}>
            <div className="hydration-station-section__text-wrapper">
                {continent === Continent.northAmerica && (
                    <Switch
                        label={trans('containers.hydrationStationSection.switch.label')}
                        hideLabel
                        name="hydration-station-type"
                        options={hydrationStationTypeOptions}
                        value={hydrationStationType}
                        onChange={setHydrationStationType}
                        className="hydration-station-section__switch"
                    />
                )}

                <Button
                    text={trans('containers.hydrationStationSection.steps.activate')}
                    onClick={scrubToActivateChapter}
                    className="hydration-station-section__title-button"
                >
                    <h2 data-active={currentTime >= chapters[Chapter.activate]} className="hydration-station-section__title">
                        {trans('containers.hydrationStationSection.steps.activate')}
                    </h2>
                </Button>

                <Button
                    text={trans('containers.hydrationStationSection.steps.activate')}
                    onClick={scrubToChooseChapter}
                    className="hydration-station-section__title-button"
                >
                    <h2 data-active={currentTime >= chapters[Chapter.choose]} className="hydration-station-section__title">
                        {trans('containers.hydrationStationSection.steps.choose')}
                    </h2>
                </Button>

                <Button
                    text={trans('containers.hydrationStationSection.steps.activate')}
                    onClick={scrubToFillChapter}
                    className="hydration-station-section__title-button"
                >
                    <h2 data-active={currentTime >= chapters[Chapter.fill]} className="hydration-station-section__title">
                        {trans('containers.hydrationStationSection.steps.fill')}
                    </h2>
                </Button>

                <p className="hydration-station-section__description">
                    {trans('containers.hydrationStationSection.description')}
                </p>

                <LinkButton
                    to={trans(appRoutes[AppRoute.yourClub].path)}
                    text={trans('containers.hydrationStationSection.callToAction')}
                    className="hydration-station-section__call-to-action"
                />
            </div>

            <div className={videoWrapperClassNames}>
                <div className="hydration-station-section__controls-wrapper">
                    {!videoHasEnded && !videoIsPlaying && (
                        <IconButton
                            icon="play"
                            text={trans('containers.hydrationStationSection.controls.play')}
                            hideLabel
                            onClick={playVideo}
                            className="hydration-station-section__control-button"
                        />
                    )}

                    {!videoHasEnded && videoIsPlaying && (
                        <IconButton
                            icon="pause"
                            text={trans('containers.hydrationStationSection.controls.pause')}
                            hideLabel
                            onClick={pauseVideo}
                            className="hydration-station-section__control-button"
                        />
                    )}

                    {videoHasEnded && (
                        <IconButton
                            icon="replay"
                            text={trans('containers.hydrationStationSection.controls.replay')}
                            hideLabel
                            onClick={playVideo}
                            className="hydration-station-section__control-button"
                        />
                    )}
                </div>

                <video
                    key={hydrationStationType}
                    ref={videoRef}
                    autoPlay
                    muted
                    playsInline
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={handleVideoEnded}
                    className="hydration-station-section__video"
                >
                    {isMobile ? (
                        <source src={mobileVideoSrc} type="video/mp4" />
                    ) : (
                        <source src={videoData.src} type={videoData.type} />
                    )}
                </video>
            </div>
        </div>
    );
};

export default HydrationStationSection;
