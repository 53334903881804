import { ClientType, ContactFormData } from '../../../../entities/Contact/Contact';
import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateName,
    validateNumberOfClubs,
    validatePhoneNumber,
    validateRequiredString,
    validateShortDescription,
} from '../../../../entities/FormValidation/FormValidationService';

export type ContactFormErrors = FormErrors<ContactFormData>;

export const validateContactFormData = (formData: ContactFormData): FormValidation<ContactFormErrors> => {
    const gymNameError = formData.clientType === ClientType.owner
        ? validateRequiredString('gymName', formData.gymName)
        : undefined;

    const numberOfClubsError = formData.clientType === ClientType.owner
        ? validateNumberOfClubs(formData.numberOfClubs)
        : undefined;

    const errors: ContactFormErrors = {
        clientType: validateRequiredString('clientType', formData.clientType),
        gymName: gymNameError,
        numberOfClubs: numberOfClubsError,
        name: validateName('name', formData.name),
        email: validateEmailAddress(formData.email),
        phoneNumber: validatePhoneNumber(formData.phoneNumber),
        comment: validateShortDescription('comment', formData.comment),
    };

    return validateForm<ContactFormErrors>(errors);
};
