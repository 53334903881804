import {
    ChangeEvent,
    FC,
    ReactElement,
    useRef,
} from 'react';

import { Button, Input, InputLabel } from '../../../components';
import { InputProps } from '../../../components/@inputs/Input/Input';

import './NumberInput.scss';

interface NumberInputProps extends Omit<InputProps, 'onChange'> {
    label: string;
    additionalLabel?: string;
    hideLabel?: boolean;
    onChange: (value: number) => void;
}

const NumberInput: FC<NumberInputProps> = ({
    label,
    additionalLabel,
    hideLabel,
    required,
    tabIndex,
    disabled,
    error = '',
    onChange,
    className = '',
    ...inputProps
}): ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleMinusClick = (): void => {
        if (inputRef.current) {
            inputRef.current.stepDown();
            onChange(Number(inputRef.current.value));
        }
    };

    const handlePlusClick = (): void => {
        if (inputRef.current) {
            inputRef.current.stepUp();
            onChange(Number(inputRef.current.value));
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => onChange(Number(event.currentTarget.value));
    const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

    return (
        <label aria-label={label} className={`number-input ${className}`}>
            {!hideLabel && (
                <InputLabel
                    label={label}
                    additionalLabel={additionalLabel}
                    className="text-input__label"
                />
            )}

            <div className="number-input__field-wrapper">
                <Input
                    {...inputProps}
                    ref={inputRef}
                    type="number"
                    required={required}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    error={error}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    className="number-input__field"
                />

                <div className="number-input__control-wrapper">
                    <Button
                        text="+"
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onClick={handlePlusClick}
                        className="number-input__control-button number-input__control-button--plus"
                    />
                    <Button
                        text="-"
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onClick={handleMinusClick}
                        className="number-input__control-button number-input__control-button--minus"
                    />
                </div>
            </div>
        </label>
    );
};

export default NumberInput;
