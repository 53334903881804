export enum ClientType {
    visitor = 'visitor',
    owner = 'owner',
}

export interface ContactFormData {
    clientType: ClientType;
    gymName?: string;
    numberOfClubs?: number;
    name: string;
    email: string;
    phoneNumber: string;
    comment: string;
    heardAbout?: string;
}
